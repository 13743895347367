module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.14._c739a92aa94b0eeb27653af5562a97d7/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":1.5}},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":1}}]}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-canonical-url_3f8deb860d3b8aea2046a80f7198ad96/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://runnerbangladesh.org","stripQueryString":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14_df2a88810062ef5a0446145f756f0085/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Runner Bangladesh Development Society","short_name":"Runner Bangladesh","start_url":"/","background_color":"#222","theme_color":"#f59c22","display":"standalone","icon":"src/assets/rbds_logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c022742a8c46bd3aeab7a10ae9b4bd7a"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@5.1_f9e1bc500da3157dd22b421e651ba98d/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#f59c22","showSpinner":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@_1fd4b860d5134b8bbc4241a1d3e428dc/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
